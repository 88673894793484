import React, { useEffect, useState } from 'react'

import toast from 'react-hot-toast'

import { GoCircleSlash } from 'react-icons/go'
import { MdPersonSearch } from 'react-icons/md'
import { TbCalendarTime } from 'react-icons/tb'
import styled from 'styled-components'

import Application from './components/Application'
import ApplicationActivity from './components/ApplicationActivity'
import EmptyApplication from '../EmptyApplication'
// import EmptyApplication from './components/EmptyApplication'

import apiClient from '../../../../../helpers/apiClient'

import noFile from '../../../../../assets/images/nofile.PNG'

const MyApplications = () => {
  const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
  const stagesBinding = {
    Screening: <MdPersonSearch className="icon__status" />,
    '1st Interview': <TbCalendarTime className="icon__status" />,
    '2nd Interview': <TbCalendarTime className="icon__status" />,
    'Skill Assessment': <TbCalendarTime className="icon__status" />,
    'Client Profiling': <MdPersonSearch className="icon__status" />,
    'Client Interview': <TbCalendarTime className="icon__status" />,
    Offer: <TbCalendarTime className="icon__status" />,
    Closed: <GoCircleSlash className="icon__status" />,
  }

  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    apiClient
      .get('/applicant/jobs', {
        params: {
          applicantId: cvData.id,
        },
      })
      .then((res) => {
        const result = res.data.resp.map((data) => {
          return {
            ...data,
            isOpen: false,
          }
        })
        setJobs(result)
        setLoading(false)
      })
      .catch((err) => {
        toast.error(err.message)
        setLoading(false)
      })
  }, [cvData.id])

  const fetchActivities = async (selectedJob) => {
    apiClient
      .get(`/applicant/${cvData.id}/interviewActivities`, {
        params: {
          interview_id: selectedJob.interviewId,
        },
      })
      .then((res) => {
        const result = res.data.resp
        const updatedJobs = jobs.map((job) => {
          if (job.interviewId === selectedJob.interviewId) {
            return {
              ...job,
              activities: result,
              isOpen: !selectedJob.isOpen,
            }
          } else {
            return job
          }
        })
        setJobs(updatedJobs)
      })
      .catch((err) => {
        toast.error(err?.message)
        setLoading(false)
      })
  }

  return (
    <StyledMyApplications>
      <br />
      <>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {jobs.map((job, index) => (
              <>
                <Application
                  key={index}
                  job={job}
                  fetchActivities={fetchActivities}
                  stagesBinding={stagesBinding}
                />
                <ApplicationActivity key={index} job={job} />
              </>
            ))}
            {jobs.length === 0 && (
              <EmptyApplication
                icon={noFile}
                message={
                  <>
                    You currently have no active applications,
                    <br /> search for a job to apply to{' '}
                  </>
                }
              />
            )}
          </>
        )}
      </>
    </StyledMyApplications>
  )
}

export const StyledMyApplications = styled.section`
  @media (max-width: 768px) {
    flex-direction: column;
  }

  margin-top: 0.5rem;

  .icon__status {
    margin-right: 6px;
    font-size: 18px;
    margin-bottom: -4px;
  }
`

export default MyApplications
