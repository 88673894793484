import React, { useState } from 'react'
import styled from 'styled-components'

import MyCv from './components/MyCV'
import MyApplications from './components/MyApplications'
import SavedApplications from './components/SavedApplications'
import SuggestedVacancies from './components/SuggestedVacancies'

const Tabs = () => {
  const types = [
    'My CV',
    'My Applications',
    'My Saved Listings',
    'My Suggested Vacancies',
  ]
  const [active, setActive] = useState(types[0])

  return (
    <>
      <ButtonGroup>
        {types.map((type) => (
          <Tab
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type}
          </Tab>
        ))}
      </ButtonGroup>

      {active === 'My CV' ? <MyCv /> : ''}
      {active === 'My Applications' ? <MyApplications /> : ''}
      {active === 'My Saved Listings' ? <SavedApplications /> : ''}
      {active === 'My Suggested Vacancies' ? <SuggestedVacancies /> : ''}
    </>
  )
}

const Tab = styled.button`
  font-size: 14px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  opacity: 1;
  padding: 5px 20px;
  color: #31374f;
  ${({ active }) =>
    active &&
    `
border-bottom: 2px solid pink;
opacity: 1;
`}
`
const ButtonGroup = styled.div`
  display: flex;
`

export default Tabs
