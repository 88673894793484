import React from 'react'

import styled from 'styled-components'
import moment from 'moment'

const EducationalBackground = ({
  educationalData,
  editIcon,
  handleEditModal,
}) => {
  return (
    <>
      <Title>Educational Background</Title>
      {educationalData?.length > 0 ? (
        educationalData?.map((educ, index) => (
          <Article key={educ.id || index}>
            <div className="left__section">
              <p className="item__title">
                {moment(educ.from).format('MMMM YYYY')} -{' '}
                {educ.currentStatus !== 'currently enrolled'
                  ? moment(educ.to).format('MMMM YYYY')
                  : 'Present'}
              </p>
            </div>
            <div className="right__section">
              <p className="item__title">
                {educ.educationLevel} in {educ.fieldOfStudy}
              </p>
              <p className="school">
                {educ.school}, {educ.schoolLocation}
              </p>
            </div>
          </Article>
        ))
      ) : (
        <p>No prior educational background</p>
      )}
      <EditIcon src={editIcon} alt="icon" onClick={handleEditModal} />
    </>
  )
}

const EditIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 0px;
  color: #31374f;
  cursor: pointer;
`

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #31374f;
`

const Article = styled.article`
  display: flex;
  padding: 10px 0;

  .left__section {
    width: 40%;
  }

  .right__section {
    width: 60%;
  }

  .item__title {
    font-size: 14px;
    color: #31374f;
    font-weight: 600;
  }

  .school {
    font-size: 12px;
    color: #31374f;
    font-weight: 400;
    line-height: 22.5px;
    text-align: justify;
  }
`

export default EducationalBackground
