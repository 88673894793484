import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import pencil from '../../../../../assets/icons/pencil.svg'

import SkillsUpdate from '../../../../../components/onboardingUpdate/SkillsUpdate'
import EducationalBackgroundUpdate from '../../../../../components/onboardingUpdate/EducationalBackgroundUpdate'
import EmploymentHistory from '../../../../../components/onboardingUpdate/EmploymentHistory'
import CharacterRefUpdate from '../../../../../components/onboardingUpdate/CharacterRefUpdate'
import Experiences from './components/Experiences'
import EducationalBackground from './components/EducationalBackground'
import Skills from './components/Skills'
import CharacterReferences from './components/CharacterReferences'
import WFHSetup from './components/WFHSetup'
import RemoteFitness from './components/RemoteFitness'

import { ModalContext } from '../../../../../context/ModalContext'
import { PagesContext } from '../../../../../context/FormPagesContext'

import { setHasSkillChange } from '../../../../../redux/feature/jobSlice'

import useFeatureFlag from '../../../../../hooks/useFeatureFlag'

import apiClient from '../../../../../helpers/apiClient'

import { getFromLocalStorage } from '../../../../../utils/util'


const MyCV = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [modal, setModal] = useState('')

  const {
    experienceState,
    setExperienceState,
    educationalData,
    setEducationalData,
    skills,
    softSkills,
    setFullSkills,
    toggle,
    eduToggle,
    characterReferences,
    setCharacterReferences,
    items,
  } = useContext(PagesContext)

  const { handleModalToggle, setRemotePage, setWorkEthicsPage } =
    useContext(ModalContext)

  const { activeFlag } = useFeatureFlag('FF_APPLICANT_MYCV_LOCAL_STORAGE')

  const wfh = activeFlag
    ? getFromLocalStorage('applicant-wfh')
    : JSON.parse(localStorage.getItem('applicant-wfh'))
  const cvData = activeFlag
    ? getFromLocalStorage('applicant-cv-data')
    : JSON.parse(localStorage.getItem('applicant-cv-data'))
  const userId = activeFlag
    ? getFromLocalStorage('user_id')
    : localStorage.getItem('user_id')
  const meta = cvData.meta !== null ? JSON.parse(cvData.meta) : null

  const characterReferenceMeta =
    cvData?.character_reference && JSON.parse(cvData.character_reference)
  const computerSpec =
    wfh !== null ? JSON.parse(wfh.computer_specification) : wfh
  const internetSpeedTest =
    wfh !== null ? JSON.parse(wfh.internet_speed_test) : wfh
  const peripherals = wfh !== null ? JSON.parse(wfh.peripherals) : wfh
  const remoteFitness = wfh !== null ? JSON.parse(wfh.meta) : wfh

  const professionalHeadlines = getFromLocalStorage('professionalHeadlinesId')
    ? JSON.parse(cvData.professional_headline).map((hl) => hl.id)
    : []

  const handleEditExperiences = () => {
    handleModalToggle()
    setModal('employment')
  }
  const handleEditEducationalBackground = () => {
    handleModalToggle()
    setModal('education')
  }
  const handleEditSkills = () => {
    handleModalToggle()
    setModal('skill')
  }
  const handleEditCharacterReferences = () => {
    handleModalToggle()
    setModal('characterRef')
  }
  const handleEditWFHSetup = () => {
    setRemotePage(1)
    history.push('/remote-setup/update')
  }
  const handleEditRemoteFitness = () => {
    setWorkEthicsPage(1)
    history.push('/remote-fitness-level/update')
  }

  const handleUpdateEmploymentHistory = (data) => {
    const newMeta = {
      ...meta,
    }

    if (toggle) {
      setExperienceState([])
      newMeta.experienceState = []
    } else if (data?.jobTitle === '') {
      return toast.error('Please add an employment history')
    } else {
      setExperienceState((prevState) => [...prevState, data])
      newMeta.experienceState = [...experienceState, data]
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleUpdateEducationalBackground = (data) => {
    const newMeta = {
      ...meta,
    }

    if (eduToggle) {
      setEducationalData([])
      newMeta.educationalData = []
    } else if (data?.educationLevel === '') {
      return toast.error('Please add an educational background')
    } else {
      setEducationalData((prevState) => [...prevState, data])
      newMeta.educationalData = [...educationalData, data]
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }

    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'educationalData',
          JSON.stringify([...educationalData, data])
        )

        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleUpdateSkills = () => {
    setFullSkills([skills, softSkills])
    const newMeta = JSON.stringify({
      ...meta,
      fullSkills: [skills, softSkills],
    })

    const mappedHeadlines = items?.filter((item) =>
      professionalHeadlines.includes(item.id)
    )

    const reqBody = {
      meta: newMeta,
      professional_headline: JSON.stringify(mappedHeadlines),
    }

    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
            professional_headline: resp.data.data.professional_headline,
          })
        )

        dispatch(setHasSkillChange(true))
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleUpdateCharacterRef = (data) => {
    setCharacterReferences(data)

    const newMeta = JSON.stringify(data)
    const reqBody = {
      character_reference: newMeta,
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            character_reference: resp.data.data.character_reference,
          })
        )
        setModal('')
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  const handleDeleteEmploymentHistoryItem = (filteredItems) => {
    const newMeta = {
      ...meta,
      experienceState: filteredItems,
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleDeleteEducationalBackgroundItem = (filteredItems) => {
    const newMeta = {
      ...meta,
      educationalData: filteredItems,
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleDeleteCharacterRef = (data) => {
    setCharacterReferences(data)
    const reqBody = {
      character_reference: JSON.stringify(data),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            character_reference: resp.data.data.character_reference,
          })
        )

        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  useEffect(() => {
    setCharacterReferences(characterReferenceMeta)
  }, [])

  // FIXME: WFH Setup and Remote Fitness Level not appearing after going to /profile immediately upon user account creation (uploaded cv)

  return (
    <>
      <SectionWrapper>
        <Experiences
          experiences={meta?.experienceState}
          editIcon={pencil}
          handleEditModal={handleEditExperiences}
        />
      </SectionWrapper>
      <SectionWrapper>
        <EducationalBackground
          educationalData={meta?.educationalData}
          editIcon={pencil}
          handleEditModal={handleEditEducationalBackground}
        />
      </SectionWrapper>

      {meta !== null && (
        <SectionWrapper className="flex">
          <Skills
            skills={meta?.fullSkills}
            editIcon={pencil}
            handleEditModal={handleEditSkills}
          />
        </SectionWrapper>
      )}

      {characterReferences && (
        <SectionWrapper>
          <CharacterReferences
            characterReferences={characterReferences}
            editIcon={pencil}
            handleEditModal={handleEditCharacterReferences}
          />
        </SectionWrapper>
      )}

      {wfh !== null && (
        <SectionWrapper>
          <WFHSetup
            internetSpeedTest={internetSpeedTest}
            computerSpec={computerSpec}
            peripherals={peripherals}
            editIcon={pencil}
            handleEditModal={handleEditWFHSetup}
          />
        </SectionWrapper>
      )}

      {remoteFitness !== null && (
        <SectionWrapper>
          <RemoteFitness
            remoteFitness={remoteFitness}
            editIcon={pencil}
            handleEditModal={handleEditRemoteFitness}
          />
        </SectionWrapper>
      )}

      {modal === 'employment' && (
        <EmploymentHistory
          handleHardUpdate={handleUpdateEmploymentHistory}
          handleHardDeleteItem={handleDeleteEmploymentHistoryItem}
        />
      )}
      {modal === 'education' && (
        <EducationalBackgroundUpdate
          handleHardUpdate={handleUpdateEducationalBackground}
          handleHardDeleteItem={handleDeleteEducationalBackgroundItem}
        />
      )}
      {modal === 'skill' && (
        <SkillsUpdate handleHardUpdate={handleUpdateSkills} />
      )}
      {modal === 'characterRef' && (
        <CharacterRefUpdate
          handleHardUpdate={handleUpdateCharacterRef}
          handleHardDelete={handleDeleteCharacterRef}
        />
      )}
    </>
  )
}

const SectionWrapper = styled.section`
  margin-top: 0.5rem;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaf8;
  position: relative;

  &.flex {
    display: flex;
    padding: 10px 0;
  }
`

export default MyCV
