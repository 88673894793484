import React, { useState, useRef, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import moment from 'moment'
import toast from 'react-hot-toast'
import { FiMail, FiPhone, FiMapPin, FiAlertCircle } from 'react-icons/fi'

import AuthLayouts from '../../layouts/AuthLayouts'

import Tabs from './tabs'
import ImageUploaderModal from './components/ImageUploaderModal'

import { PagesContext } from '../../context/FormPagesContext'
import { ModalContext } from '../../context/ModalContext'

import { getFromLocalStorage } from '../../utils/util'

import apiClient from '../../helpers/apiClient'

import user from '../../assets/images/user.svg'

import { baseUrl } from '../../constants/constant'

const Profile = () => {
  const userData = getFromLocalStorage('applicant-data')
  const cvData = getFromLocalStorage('applicant-cv-data')
  const profileImgLink = getFromLocalStorage('profileImgLink')
  const applicantName =
    cvData?.name ||
    `${cvData?.first_name} ${cvData?.middle_name} ${cvData?.last_name}`
  const userHeadline =
    cvData?.professional_headline && JSON.parse(cvData.professional_headline)

  const [uploadLoading, setUploadLoading] = useState(false)
  const [imgLink, setImgLink] = useState('')
  const [imageUploaderModal, setImageUploaderModal] = useState(false)

  const imageRef = useRef(null)
  const avatarRef = useRef(null)

  const { setUserDetails, setFile, file } = useContext(PagesContext)
  const { setModal, setCloseModal, setHeaderToggle } = useContext(ModalContext)

  const history = useHistory()

  const userId = getFromLocalStorage('user_id')

  useEffect(() => {
    if (userId && userId !== 'null') {
      apiClient
        .get(`/applicant`, {
          params: {
            userId: userId,
          },
        })
        .then((res) => {
          console.log(res)
          if (!res.data[0]?.name) {
            history.push('/onboarding')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])

  useEffect(() => {
    setImgLink(profileImgLink || cvData?.cv_image_url)
  }, [profileImgLink])

  /**
   * Handles the change event when an image is uploaded.
   *
   * @param {Event} e - The event object representing the change event.
   * @return {void}
   */
  const handleChangeImage = (e) => {
    const uploadedFile = e.target.files[0]

    // check if file is an image
    if (
      uploadedFile.type !== 'image/jpeg' &&
      uploadedFile.type !== 'image/png'
    ) {
      toast.error('Please upload a valid image')
    } else if (uploadedFile.size > 3000000) {
      toast.error('Image too big (max 3MB)')
    } else {
      if (imageRef.current.files[0]) {
        const fileReader = new FileReader()

        fileReader.onload = function (event) {
          setModal(true)
          avatarRef.current.src = event.target.result
        }

        fileReader.readAsDataURL(imageRef.current.files[0])
        setFile(e.target.files[0])
      }
    }
  }

  /**
   * Handles the image uploader modal.
   *
   * @return {void}
   */
  const handleImageUploaderModal = () => {
    setImageUploaderModal(true)
    setModal(true)
  }

  /**
   * Handles the preview of the CV.
   *
   * @return {void}
   */
  const handlePreviewCv = () => {
    setCloseModal(true)
    setHeaderToggle(true)
  }

  /**
   * Handles the saving of an image.
   *
   * @return {void}
   */
  const handleSaveImage = () => {
    if (!uploadLoading) {
      if (file) {
        setUploadLoading(true)
        const formData = new FormData()
        formData.append('profileImage', file)
        formData.append('applicant_id', cvData.applicant_id)
        apiClient
          .put(
            `${baseUrl}/user/${userData.id}/profile-image-update`,
            formData,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': `multipart/form-data;`,
              },
            }
          )
          .then((res) => {
            localStorage.setItem(
              'profileImgLink',
              res?.data?.data?.cv_image_url
            )
            localStorage.removeItem('file')
            setFile([])
            setUserDetails((prevState) => ({
              ...prevState,
              profileImage: res?.data?.data?.cv_image_url,
            }))
            toast.success('Profile picture updated successfully.')
            setUploadLoading(false)
            setModal(false)
            setImageUploaderModal(false)
          })
          .catch((err) => {
            setUploadLoading(false)
            setModal(false)
            setImageUploaderModal(false)
            toast.error(
              err?.response?.data?.message ||
                'Error occured when uploading image.'
            )
          })
      } else {
        toast.error('Please upload an image.')
      }
    }
  }

  return (
    <AuthLayouts width="1000px">
      <StyledProfile>
        <div className="column-1">
          <div className="img-upload">
            <div className="userImage">
              <label htmlFor="userImage">
                <img
                  className="avatar"
                  ref={avatarRef}
                  src={!imgLink || imgLink === 'null' ? user : imgLink}
                  alt="uploaded image"
                />
              </label>
            </div>
          </div>
          <div className="button__group">
            <button
              onClick={handleImageUploaderModal}
              className="edit-profile-image-btn"
            >
              Edit Profile Image
            </button>
            <button
              onClick={handlePreviewCv}
              className="edit-profile-image-btn"
            >
              Preview CV
            </button>
          </div>
          <h2>{applicantName}</h2>
          <p className="user-bio">
            {userHeadline && userHeadline.length > 0 && (
              <div className="user-headline">
                {userHeadline.map((item, index) => (
                  <div className="user-healine-items" key={item.id}>
                    {item.item}{' '}
                    {index !== userHeadline.length - 1 && (
                      <>
                        {' '}
                        <span className="user-headline-span">
                          &#8226; &nbsp;
                        </span>
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
          </p>
          {cvData && cvData.length > 0 && (
            <p className="user-sum">
              {cvData.professional_summary.replace(/<\/?p>|"/g, '')}
            </p>
          )}
          <ul className="user-contact">
            <li>
              <FiMail className="user-contact-icon" />
              {userData.email}
            </li>
            <li>
              <FiPhone className="user-contact-icon" />+ {userData.phone_number}
            </li>
            <li>
              <FiMapPin className="user-contact-icon" />
              {userData.city}, {userData.country}
            </li>
          </ul>
          <span className="user-last-login">
            <FiAlertCircle className="user-contact-icon" />
            Last Updated {moment(userData.updated_at).format('MMMM DD, YYYY')}
          </span>
        </div>

        <div className="column-2">
          <Tabs />
        </div>

        {imageUploaderModal && (
          <ImageUploaderModal
            loading={uploadLoading}
            onChangeImage={handleChangeImage}
            onSaveImage={handleSaveImage}
            avatarRef={avatarRef}
            imageRef={imageRef}
            imgLink={imgLink}
          />
        )}
      </StyledProfile>
    </AuthLayouts>
  )
}

export default Profile

export const StyledProfile = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;

  h2 {
    color: #31374f;
    margin: 15px 0;
  }

  .user-bio {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #31374f;
    margin-bottom: 15px;
  }

  .user-headline {
    display: flex;
    flex-wrap: wrap;
  }

  .user-headline-span {
    font-size: 10px;
  }

  .user-healine-items {
    display: inline;
  }

  .user-sum {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    display: block;
  }
  .user-contact {
    list-style-type: none;
    display: block;
  }

  .user-contact li {
    margin-bottom: 8px;
    font-size: 12px;
  }
  .user-contact-icon {
    margin-right: 10px;
  }

  .user-last-login {
    font-style: italic;
    font-size: 12px;
    font-weight: 500;
    background: #f8f8f8;
    padding: 5px 10px;
    margin: 15px 0;
    display: block;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .column-1 {
      width: 100% !important;
    }

    .column-2 {
      width: 100% !important;
      padding-left: 0 !important;
    }

    .user-sum {
      display: none;
    }

    .user-last-login {
      display: none;
    }

    .user-contact {
      display: none;
    }
  }
  .user-image {
    width: 125px;
    height: 125px;
    object-fit: cover;
    margin-right: 1.5rem;
    border-radius: 50%;
  }
  .column-1 {
    float: left;
    width: 40%;
    padding-right: 20px;

    .button__group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .img-upload {
      width: 122px;

      .userImage {
        width: 100%;
        height: 122px;
        border-radius: 50%;
        overflow: hidden;
        background: #d9d9ef;
        transition: all 0.3s ease;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover {
          background: #676e92;
        }

        label {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            font-size: 3rem;
            color: #fff;
          }
        }
      }

      p {
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 3%;
        text-align: center;
        margin-top: 10px;
      }
    }

    .edit-profile-image-btn {
      margin-top: 0.5rem;
      width: 50%;
      border: 1px solid #ff7b9b;
      color: #ff5880;
      padding: 0.75rem 1.3rem;
      border-radius: 100px;
      background: none;
      outline: none;
      cursor: pointer;
    }
  }

  .column-2 {
    float: left;
    width: 60%;
    padding-left: 20px;
  }

  .column > p {
    margin-bottom: 15px;
  }

  /* Clear floats after the columns */
  .row:after {
    content: '';
    display: table;
    clear: both;
  }

  h2,
  p {
    margin-bottom: 5px;
  }
`
