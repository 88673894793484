import React from 'react'

import styled from 'styled-components'

import { formatTimeDifference } from '../../../../../../utils/util'

const ApplicationActivity = ({ job }) => {
  return (
    <StyledActivity>
      {job.isOpen && job?.activities?.length > 0 && (
        <h4 className="job__activities__title">Job Activity</h4>
      )}
      {job.isOpen &&
        job?.activities?.map((activity, index) => (
          <div className="activity__item" key={index}>
            <div className={`circle__dot ${index === 0 && 'active'}`}></div>
            <div className="activity__item__title">
              {activity.activities}
              <span className="activity__item__time">
                {formatTimeDifference(
                  new Date(activity.created_at),
                  new Date()
                )}
              </span>
            </div>
          </div>
        ))}
    </StyledActivity>
  )
}

const StyledActivity = styled.div`
  .job__activities__title {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #31374f;
    font-size: 14px;
  }

  .activity__item {
    display: flex;
    margin-bottom: 15px;

    .circle__dot {
      background: red;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #e9ebf2;
      position: relative;
      margin-right: 10px;

      &.active {
        background: #1aaf8b;

        &:after {
          background: #fff;
        }
      }

      &:after {
        content: '';
        width: 1px;
        height: 32px;
        background: #e9ebf2;
        position: absolute;
        bottom: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        display: block;
        z-index: -1;
      }
    }

    span {
      color: #31374f;
      margin-top: -4px;
    }

    .activity__item__title {
      font-size: 14px;
    }

    .activity__item__time {
      display: block;
      margin-top: 0.25rem;
      font-size: 10px;
    }
  }
`

export default ApplicationActivity
