import React from 'react'

import styled from 'styled-components'
import pluralize from 'pluralize'

import { FiClock } from 'react-icons/fi'
import { MdOutlineDateRange, MdOutlineSupervisorAccount } from 'react-icons/md'

import { Button } from '../../../../../../components/Button'

import { numberCommaSeparator } from '../../../../../../utils/util'

const SuggestedJobs = ({ job, onApplyJob, onViewListing }) => {
  return (
    <StyledSuggestedJobs>
      <div className="suggested__jobs__wrapper">
        <div className="suggested__jobs__header">
          <h4 className={`suggested__jobs__title ${job?.status}`}>
            {job?.title}
          </h4>
        </div>
        <p className="salaries">
          {job?.currencies} {numberCommaSeparator(job?.min_salary)} -{' '}
          {job?.currencies} {numberCommaSeparator(job?.max_salary)}
        </p>
        <div className="more_details">
          <div className="details">
            <MdOutlineSupervisorAccount className="icon" />
            <p className="content">
              Hiring {job?.active_vacancy}{' '}
              {pluralize('Candidate', job?.active_vacancy)}
            </p>
          </div>
          <div className="details">
            <MdOutlineDateRange className="icon" />
            <p className="content">
              {job?.job_type[0].toUpperCase() + job?.job_type.slice(1)}
            </p>
          </div>
          <div className="details">
            <FiClock className="icon" />
            <p className="content">{job?.time_shift}</p>
          </div>
        </div>
        <div className="suggested__jobs__button__group">
          <Button radius={'none'} height="3rem" handleClick={onApplyJob}>
            Apply Now
          </Button>
          <Button
            radius={'none'}
            btnClass="secondary"
            height="3rem"
            handleClick={onViewListing}
          >
            View Listing
          </Button>
        </div>
      </div>
    </StyledSuggestedJobs>
  )
}

const StyledSuggestedJobs = styled.div`
  .suggested__jobs__wrapper {
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea;

    .suggested__jobs__header {
      display: flex;
      justify-content: space-between;

      .suggested__jobs__title {
        font-weight: 600;
        font-size: 20px;
        color: #31374f;

        &.closed {
          color: #909090;
        }
      }

      .suggested__jobs__header__icon {
        font-size: 26px;
        cursor: pointer;
      }
    }

    .suggested__jobs__desc {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #31374f;
      font-size: 14px;

      &.closed {
        color: #909090;
      }
    }

    .suggested__jobs__button__group {
      display: flex;
      gap: 0.5rem;
      color: #1a1a1a;
      max-width: 300px;
      text-align: center;
      padding: 5px 0 8px;
      border-radius: 5px;
      font-size: 14px;
    }

    .inactive__listing {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 295px;
      height: 35px;
      background-color: #e4e4e4;
      padding: 1rem;

      .inactive__listing__icon {
        font-size: 24px;
        color: #4f4f4f;
      }
    }

    .salaries {
      font-size: 1rem;
      font-weight: 300;
      color: #31374f;
    }

    .more_details {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.75rem;
      margin-bottom: 0.3rem;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .details {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .icon {
          font-size: 1.25rem;
          color: #31374f;
        }

        .content {
          font-size: 0.75rem;
          font-weight: 300;
          color: #31374f;
        }
      }
    }
  }

  .button__link {
    background: none;
    color: #007bff;
    border: none;
    padding: 10px;
    font: inherit;
    text-decoration: underline;
    outline: none;
  }
`

export default SuggestedJobs
