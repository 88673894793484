import React from 'react'

import styled from 'styled-components'
import moment from 'moment'
import { BsBookmarkFill } from 'react-icons/bs'
import { MdInfo } from 'react-icons/md'

import { Button } from '../../../../../../components/Button'

const SavedApplication = ({
  job,
  onUnsaveListing,
  onApplyJob,
  onViewListing,
}) => {
  return (
    <StyledSavedApplication>
      <div className="saved__listings__wrapper">
        <div className="saved__listings__header">
          <h4 className={`saved__listings__title ${job?.status}`}>
            {job?.Jobs?.title}
          </h4>
          <BsBookmarkFill
            className="saved__listings__header__icon"
            onClick={onUnsaveListing}
          />
        </div>
        <p className={`saved__listings__desc ${job?.status}`}>
          Saved {moment(job?.Jobs?.created_at).format('MMMM DD, YYYY')}
        </p>
        {job?.Jobs?.status === 'active' ? (
          <div className="saved__listings__button__group">
            <Button radius={'none'} height="3rem" handleClick={onApplyJob}>
              Apply Now
            </Button>
            <Button
              radius={'none'}
              btnClass="secondary"
              height="3rem"
              handleClick={onViewListing}
            >
              View Listing
            </Button>
          </div>
        ) : (
          <div className="inactive__listing">
            <MdInfo className="inactive__listing__icon" />
            No longer accepting applications
          </div>
        )}
      </div>
    </StyledSavedApplication>
  )
}

const StyledSavedApplication = styled.div`
  .saved__listings__wrapper {
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea;

    .saved__listings__header {
      display: flex;
      justify-content: space-between;

      .saved__listings__title {
        font-weight: 600;
        font-size: 20px;
        color: #31374f;

        &.closed {
          color: #909090;
        }
      }

      .saved__listings__header__icon {
        font-size: 26px;
        cursor: pointer;
      }
    }

    .saved__listings__desc {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #31374f;
      font-size: 14px;

      &.closed {
        color: #909090;
      }
    }

    .saved__listings__button__group {
      display: flex;
      gap: 0.5rem;
      color: #1a1a1a;
      max-width: 300px;
      text-align: center;
      padding: 5px 0 8px;
      border-radius: 5px;
      font-size: 14px;
    }

    .inactive__listing {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 295px;
      height: 35px;
      background-color: #e4e4e4;
      padding: 1rem;

      .inactive__listing__icon {
        font-size: 24px;
        color: #4f4f4f;
      }
    }
  }
`

export default SavedApplication
