import React, { useState, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import toast from 'react-hot-toast'

import JobApplicationModal from '../../../components/JobApplicationModal'
import JobDetailsModal from '../../../components/JobDetailsModal'
import SuggestedJobs from './components/SuggestedJobs'
import EmptyApplication from '../EmptyApplication'

import { ModalContext } from '../../../../../context/ModalContext'

import { getFromLocalStorage } from '../../../../../utils/util'

import ReactGA4 from '../../../../../lib/ReactGA4'

import {
  selectJob,
  setSuggestedJobsModal,
} from '../../../../../redux/feature/jobSlice'

import bookmark from '../../../../../assets/images/bookmark.png'

import apiClient from '../../../../../helpers/apiClient'

const SuggestedVacanciesTab = () => {
  const dispatch = useDispatch()

  const { suggestedJobsModal } = useSelector((state) => state.jobs)

  const [isLoading, setIsLoading] = useState(true)
  const [jobs, setJobs] = useState([])
  const { setModal } = useContext(ModalContext)

  const [showAllJobs, setShowAllJobs] = useState(false)

  const userId = getFromLocalStorage('user_id')

  const handleJobApplication = (job) => {
    dispatch(selectJob(job))
    dispatch(setSuggestedJobsModal('applyJob'))
    setModal(true)
  }

  const handleViewListing = (job) => {
    let updatedJob = { ...job, is_saved: false }

    const request = {
      applicant_id: userId.toString(),
      job_id: job?.id?.toString(),
    }
    const urlParams = new URLSearchParams(request).toString()

    apiClient
      .get(`/saved-jobs?${urlParams}`)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          const isSaved = response?.data?.data[0].is_saved === 1
          updatedJob = { ...updatedJob, is_saved: isSaved }
        }
        dispatch(selectJob(updatedJob))
        const reactGA4 = new ReactGA4()
        reactGA4.sendPageView(`View Listing - ${job.title}`)

        dispatch(setSuggestedJobsModal('viewJob'))
        setModal(true)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  const handleSeeMore = () => {
    setShowAllJobs(true)
  }

  useEffect(() => {
    setIsLoading(true)
    apiClient
      .get('/suggested-jobs', {
        params: {
          user_id: userId,
          status: 'active',
        },
      })
      .then((res) => {
        setJobs(res?.data?.data?.jobs)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        setIsLoading(false)
      })
  }, [])

  return (
    <StyledSuggestedJobsTab>
      <>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {suggestedJobsModal === 'applyJob' && <JobApplicationModal />}
            {suggestedJobsModal === 'viewJob' && <JobDetailsModal />}

            {jobs?.slice(0, showAllJobs ? jobs.length : 5).map((job, index) => (
              <SuggestedJobs
                job={job}
                key={index}
                onApplyJob={() => {
                  handleJobApplication(job)
                }}
                onViewListing={() => {
                  handleViewListing(job)
                }}
              />
            ))}
            <br />
            {!showAllJobs && jobs?.length > 5 && (
              <button className="button__link" onClick={handleSeeMore}>
                See More
              </button>
            )}

            {jobs?.length === 0 && (
              <EmptyApplication
                icon={bookmark}
                message={
                  <>
                    You currently have no suggested jobs,
                    <br /> search for a job to apply to{' '}
                  </>
                }
              />
            )}
          </>
        )}
      </>
    </StyledSuggestedJobsTab>
  )
}

export const StyledSuggestedJobsTab = styled.section`
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .button__link {
    background: none;
    color: #007bff;
    border: none;
    padding: 10px;
    font: inherit;
    text-decoration: underline;
    outline: none;
  }
`

export default SuggestedVacanciesTab
