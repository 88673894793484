import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import toast from 'react-hot-toast'

import JobApplicationModal from '../../../components/JobApplicationModal'
import JobDetailsModal from '../../../components/JobDetailsModal'
import EmptyApplication from '../EmptyApplication'
import SavedApplication from './components/SavedApplication'

import { ModalContext } from '../../../../../context/ModalContext'

import {
  selectJob,
  setSavedJobsModal,
} from '../../../../../redux/feature/jobSlice'

import { getFromLocalStorage } from '../../../../../utils/util'

import ReactGA4 from '../../../../../lib/ReactGA4'

import apiClient from '../../../../../helpers/apiClient'

import bookmark from '../../../../../assets/images/bookmark.png'

const SavedApplicationsTab = () => {
  const dispatch = useDispatch()

  const { savedJobsModal } = useSelector((state) => state.jobs)

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [updated, setUpdated] = useState(false)

  const { setModal } = useContext(ModalContext)

  const cvDetails = getFromLocalStorage('applicant-cv-data')

  useEffect(() => {
    apiClient
      .get('/saved-jobs', {
        params: {
          applicant_id: cvDetails.id,
          is_saved: true,
          with_related: ['Jobs'],
        },
      })
      .then((res) => {
        setData(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        setIsLoading(false)
      })
  }, [cvDetails.id, updated])

  const handleJobApplication = (job) => {
    dispatch(selectJob(job))
    dispatch(setSavedJobsModal('applyJob'))
    setModal(true)
  }

  const handleViewListing = (job) => {
    dispatch(selectJob(job))

    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView(`View Listing - ${job.title}`)

    dispatch(setSavedJobsModal('viewJob'))
    setModal(true)
  }

  const handleUnsaveListing = (job) => {
    const reqBody = {
      applicant_id: cvDetails?.id?.toString(),
      job_id: job?.job_id?.toString(),
    }
    apiClient
      .post(`job/saved`, reqBody)
      .then((res) => {
        toast.success(res?.data?.resp?.successMessage)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => {
        setUpdated(!updated)
      })
  }

  return (
    <StyledSavedApplicationTab>
      <>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {savedJobsModal === 'applyJob' && <JobApplicationModal />}
            {savedJobsModal === 'viewJob' && <JobDetailsModal />}

            {data?.map((job, index) => (
              <SavedApplication
                key={index}
                job={job}
                onUnsaveListing={() => handleUnsaveListing(job)}
                onApplyJob={() => {
                  handleJobApplication(job?.Jobs)
                }}
                onViewListing={() => {
                  const updatedJob = { ...job?.Jobs, is_saved: true }
                  handleViewListing(updatedJob)
                }}
              />
            ))}
            {data?.length === 0 && (
              <EmptyApplication
                icon={bookmark}
                message={
                  <>
                    You currently have no saved listings,
                    <br /> search for a job to apply to{' '}
                  </>
                }
              />
            )}
          </>
        )}
      </>
    </StyledSavedApplicationTab>
  )
}

export const StyledSavedApplicationTab = styled.section`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export default SavedApplicationsTab
