import React from 'react'

import styled from 'styled-components'

const RemoteFitness = ({ remoteFitness, editIcon, handleEditModal }) => {
  return (
    <Article>
      <Title>Remote Fitness Level</Title>
      <ul>
        <li>
          I am ready to work from home permanently. I will be working from{' '}
          <strong>{remoteFitness?.workStation?.toLowerCase()}</strong>.
        </li>
        <li>
          My hardware set-up is perfect for working from home. I have a{' '}
          <strong>{remoteFitness?.device?.toLowerCase()}</strong>
        </li>
        <li>
          I have the same mindset and am currently looking for a{' '}
          <strong>{remoteFitness?.workSchedule?.toLowerCase()}</strong>.
        </li>
        <li>
          Considering my personal lifestyle and professional experience, I work
          best during <strong>{remoteFitness?.workShift?.toLowerCase()}</strong>
          .
        </li>
        <li>
          I am dedicated to building my work-from-home career and{' '}
          <strong>{remoteFitness?.timeTracker?.toLowerCase()}</strong>.
        </li>
      </ul>
      <EditIcon src={editIcon} alt="icon" onClick={handleEditModal} />
    </Article>
  )
}

const EditIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 0px;
  color: #31374f;
  cursor: pointer;
`

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #31374f;
  margin-bottom: 0.5rem;
`

const Article = styled.article`
  display: flex;
  flex-wrap: wrap;

  ul {
    padding-left: 1rem;
    margin: 0;
  }
`

export default RemoteFitness
