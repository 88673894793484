import React from 'react'

import styled from 'styled-components'

const EmptyApplication = ({ icon, message }) => {
  return (
    <StyledEmptyApplication>
      {' '}
      <img src={icon} className="empty__icon" />
      <p>
        {message}
        <a
          href="/home"
          target="_blank"
          rel="noopener noreferrer"
          className="text"
        >
          here
        </a>
      </p>
    </StyledEmptyApplication>
  )
}

const StyledEmptyApplication = styled.div`
  text-align: center;

  .empty__icon {
    width: 100px;
  }

  p {
    color: #31374f;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`

export default EmptyApplication
