import React from 'react'

import styled from 'styled-components'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { FcGoogle } from 'react-icons/fc'
import moment from 'moment'

const Application = ({ job, fetchActivities, stagesBinding }) => {
  return (
    <StyledApplication className="jobs">
      {job?.isOpen && job?.activities?.length > 0 ? (
        <AiOutlineUp
          className="arrow__icons"
          onClick={() => fetchActivities(job)}
        />
      ) : (
        <AiOutlineDown
          className="arrow__icons"
          onClick={() => fetchActivities(job)}
        />
      )}
      <div className="job">
        <h4 className="job__title">{job?.title}</h4>
        <p className="job__desc">
          Applied {moment(job?.created_at).format('MMMM DD, YYYY')}
        </p>
        <div className="job__stages">
          {job?.stages && (
            <span>
              {' '}
              {stagesBinding[job?.stages]}
              {job?.stages}
            </span>
          )}
        </div>
        {job?.status === 'active'
          ? job?.meet_url &&
            (job?.stages === '1st Interview' ||
              job?.stages === '2nd Interview') && (
              <>
                <div className="schedule">
                  <span>
                    <b>Schedule:</b>&nbsp;
                  </span>
                  <span className="schedule__time">
                    {moment(job?.start_time).format('MMMM DD, YYYY')} •{' '}
                    {moment(job?.start_time).format('hh:mmA')} -{' '}
                    {moment(job?.end_time).format('hh:mmA')} GMT +8
                  </span>
                </div>
                <div className="calendly__buttons">
                  <a
                    className="calendly__link"
                    href={job?.meet_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FcGoogle className="google__icon" />
                    Join Google Meet
                  </a>
                </div>
              </>
            )
          : job?.meet_url !== null &&
            job?.status === 'closed' &&
            job?.stages === 'first_interview' && (
              <>
                <div className="schedule">
                  <span>
                    <b>Schedule:</b>&nbsp;
                  </span>
                  <span className="schedule__time">
                    {moment(job?.start_time).format('MMMM DD, YYYY')} •{' '}
                    {moment(job?.start_time).format('hh:mmA')} -{' '}
                    {moment(job?.end_time).format('hh:mmA')} GMT +8
                  </span>
                </div>
                <div className="calendly__buttons">
                  <a
                    className="calendly__link"
                    href={job?.meet_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FcGoogle className="google__icon" />
                    Join Google Meet
                  </a>
                </div>
              </>
            )}
        <br />
      </div>
    </StyledApplication>
  )
}

const StyledApplication = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .arrow__icons {
    font-size: 20px;
    color: #31374f;
    cursor: pointer;
  }

  .job {
    .job__title {
      font-weight: 600;
      font-size: 20px;
      color: #31374f;
    }

    .job__desc {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #31374f;
      font-size: 14px;
    }

    .job__stages {
      color: #1a1a1a;
      background: #dedede;
      max-width: 150px;
      text-align: center;
      padding: 8px 8px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .schedule {
    margin: 10px 0;

    .schedule__time {
      padding: 0.5rem;
      font-weight: 400;
    }
  }

  .calendly__buttons {
    display: flex;
    gap: 0.25rem;

    @media (max-width: 768px) {
      flex-direction: column;
      max-width: 220px;
    }

    .calendly__link {
      border: 1px solid #aeb3c9;
      padding: 1rem;
      display: flex;
      text-decoration: none;
      color: #0e1324;

      .google__icon {
        margin-right: 8px;
        font-size: 22px;
      }
    }
  }
`

export default Application
