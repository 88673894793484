import React from 'react'

import styled from 'styled-components'

const WFHSetup = ({
  internetSpeedTest,
  computerSpec,
  peripherals,
  editIcon,
  handleEditModal,
}) => {
  return (
    <Article>
      <Title>WFH Setup</Title>
      <ul>
        <li>
          <span className="title">Download (MBPS)</span>
          <span className="description">
            {internetSpeedTest?.downloadSpeed}
          </span>
        </li>
        <li>
          <span className="title">Upload (MBPS)</span>
          <span className="description">{internetSpeedTest?.uploadSpeed}</span>
        </li>
        <li>
          <span className="title">Ping (MS)</span>
          <span className="description">{internetSpeedTest?.pingTime}</span>
        </li>
        <li>
          <span className="title">Operating System</span>
          <span className="description">{computerSpec?.os}</span>
        </li>
        <li>
          <span className="title">OS Version</span>
          <span className="description">{computerSpec?.osVersion}</span>
        </li>
        <li>
          <span className="title">Processor</span>
          <span className="description">{computerSpec?.processor}</span>
        </li>
        <li>
          <span className="title">Installed RAM</span>
          <span className="description">{computerSpec?.ram} GB</span>
        </li>
        <li>
          <span className="title">Webcam</span>
          <span className="description">{peripherals?.webCamera}</span>
        </li>
        <li>
          <span className="title">Headset</span>
          <span className="description">{peripherals?.headSet}</span>
        </li>
        <li>
          <span className="title">Microphone</span>
          <span className="description">{peripherals?.microphone}</span>
        </li>
      </ul>
      <EditIcon src={editIcon} alt="icon" onClick={handleEditModal} />
    </Article>
  )
}

const EditIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 0px;
  color: #31374f;
  cursor: pointer;
`

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #31374f;
  margin-bottom: 0.5rem;
`

const Article = styled.article`
  display: flex;
  flex-wrap: wrap;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    flex-basis: calc(50% - 10px);
    margin-bottom: 5px;
  }

  li span {
    width: 50%;
    display: inline-block;
    padding-right: 5px;
  }

  .title {
    font-size: 14px;
    color: #31374f;
    font-weight: 400;
  }

  .description {
    font-size: 14px;
    color: #31374f;
    font-weight: 600;
  }
`

export default WFHSetup
